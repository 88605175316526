import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import customer from "../../assets/img/mert-haciosmanoglu.png"
import liva from "../../assets/img/liva-site-preview.png"
import detail from "../../assets/img/liva-convert-detail-one.png"
import detail1 from "../../assets/img/liva-convert-detail-two.png"
import detail2 from "../../assets/img/liva-convert-detail-three.png"
import detail3 from "../../assets/img/liva-convert-detail-four.png"
import detail4 from "../../assets/img/liva-convert-detail-five.png"
import detail5 from "../../assets/img/liva-convert-detail6.png"
import detail6 from "../../assets/img/liva-convert-detail7.png"
import OtherCaseStudy from "../../components/other-case"

import bengu from "../../assets/img/teams/17.jpg"
import serife from "../../assets/img/teams/20.jpg"  
import aysima from "../../assets/img/teams/35.jpg"
import zeynep from "../../assets/img/teams/33.jpg"
import duygu from "../../assets/img/teams/41.jpg"

import refDesign from "../../assets/img/ref-design.png"
import squad from "../../assets/img/squad.png";
import video from "../../assets/img/liva-pastacilik-basari-video.mp4";

const ReferancesDetail = () => (
    <Layout>
        <SEO title="Liva Pastacılık Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>Liva Pastacılık</h1>
                <p>1993’ten beri 8 şubesi ile Ankara’da hizmet veren Liva Pastacılık, yıllar içinde yükselen bir başarı grafiği elde etmiştir. Dijital atılım yapmak isteyen şirket ile 2016 yılında SEO, SEM, dijital reklam yönetimi, web sayfası tasarımı ve sosyal medya yönetimi alanlarında iş birliği gerçekleştirildi.</p>
                <p>Liva Pastacılık’ın şu anda ulaştığı aylık sipariş adeti, sıfır noktasından başlatılan dijital operasyon sayesinde yalnızca Ankara ili içinde 3000’den fazla.</p>
                <h2>Sorunlar</h2>
                <ul>
                    <li>&bull; Edinilen trafiğe kıyasla dönüşüm oranının %1 seviyesinde olması,</li>
                    <li>&bull; Aylık ciro artışının istenilen düzeyde olmaması,</li>
                    <li>&bull; Tekrarlanan siparişlerin %20 oranında olması,</li>
                    <li>&bull; Hedef anahtar kelimelerin arama motorlarında 2. sayfa konumunda yer alması.</li>
                </ul>

                <h2>Çözüm</h2>
                <p>Öncelikle Google Analytics üzerinden dönüşüm oranının sektör ortalamasından düşük olmasının nedenlerini tespit ettik. Büyük bir kısmı website tasarımı ve ürün çeşitliliği ile ilgili olan sorunların çözümüne yönelik Shopify altyapısı kullanarak yeni bir websitesi tasarımı yaptık. Bu sürede ürün çeşitliliğini artırarak dönüşüm oranı problemini de çözümledik. Müşteri sadakati ve organik trafik kazanımı için stratejiler oluşturarak aşağıda anlatılan biçimde çözümler ürettik.</p>
            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs">
                    <div className="box">
                        <span>%5.79</span>
                        <p>E-Ticaret ciro<br />dönüşüm oranı</p>
                    </div>
                    <div className="box">
                        <span>+4500</span>
                        <p>‘den fazla aylık<br />sipariş adeti</p>
                    </div>
                    <div className="box">
                        <span>+90k</span>
                        <p>Aylık edinilen <br /> organik trafik</p>
                    </div>
                    <div className="box">
                        <span>+%61</span>
                        <p>Aylık sipariş<br /> tekrarı oranı</p>
                    </div>
                </div>
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/eposta-pazarlama/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 86 86">
                                <g id="Icons-_-Communication-_-Mail-heart" data-name="Icons-/-Communication-/-Mail-heart" transform="translate(0.169 -0.307)">
                                    <rect id="bound" width="86" height="86" transform="translate(-0.169 0.307)" fill="none" />
                                    <path id="Combined-Shape" d="M8.569,2H51.4a3.569,3.569,0,0,1,3.569,3.569V41.262A3.569,3.569,0,0,1,51.4,44.831H8.569A3.569,3.569,0,0,1,5,41.262V5.569A3.569,3.569,0,0,1,8.569,2Zm27.84,7.138c-2.3,0-4.985,2.605-6.425,4.283-1.439-1.678-4.127-4.283-6.425-4.283-4.068,0-6.425,3.173-6.425,7.21q0,6.71,12.849,14.2,12.849-7.5,12.849-13.92C42.834,12.6,40.477,9.138,36.409,9.138Z" transform="translate(13.236 5.138)" fill="#111" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M5.829,6.816,35.123,27.972,64.417,6.816a1.785,1.785,0,0,1,2.829,1.447V44.034a7.138,7.138,0,0,1-7.138,7.138H10.138A7.138,7.138,0,0,1,3,44.034V8.263A1.785,1.785,0,0,1,5.829,6.816Z" transform="translate(8.097 16.643)" fill="#111" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Eposta Pazarlama
                        </div>
                        <div className="box">
                            <Link to="/ui-ux-tasarim/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            UI&UX Tasarım
                        </div>
                        <div className="box">
                            <Link to="/arama-motoru-optimizasyonu/"></Link>
                            <svg style={{ marginLeft: -7 }} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 79 80">
                                <g id="Icons-_-Shopping-_-Chart-line_1" data-name="Icons-/-Shopping-/-Chart-line#1" transform="translate(-0.462 0.373)">
                                    <rect id="bound" width="79" height="80" transform="translate(0.462 -0.373)" fill="none" />
                                    <path id="Path-95" d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z" transform="translate(6.895 7.094)" fill="#000000" />
                                    <path id="Path-97" d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z" transform="translate(16.088 15.138)" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            SEO
                        </div>
                        <div className="box">
                            <Link to="/digital-ads/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Shopping-_-Cart_1" data-name="Icons-/-Shopping-/-Cart#1" transform="translate(0.452 0.373)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.452 -0.373)" fill="none" />
                                    <path id="Combined-Shape" d="M52.453,31.233l-2.2,13.227-.008.046a3.161,3.161,0,0,1-3.675,2.544L10.63,40.517a3.161,3.161,0,0,1-2.474-2.241L2.389,18.088a3.161,3.161,0,0,1,3.039-4.029H48.813l1.938-8.306a3.729,3.729,0,0,1,3.632-2.882h6.527a3.729,3.729,0,1,1,0,7.459H57.342Z" transform="translate(4.899 6.203)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M9.741,27.482a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,9.741,27.482Zm28.447,0a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,38.188,27.482Z" transform="translate(10.804 38.894)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Digital Ads
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Yöneticisi:</li>
                            <li>Digital Ads:</li>
                            <li>SEO:</li>
                            <li>Website Geliştirme:</li>
                            <li>İçerik Yazarı:</li>
                        </ul>
                        <ul>
                            <li><img src={duygu} alt="Duygu serteser" />Duygu Serteser</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={bengu}  alt="Bengü Dinçer" />Bengü Dinçer</li>
                            <li><img src={zeynep} />Zeynep Oralel</li>
                            <li><img src={aysima} />Aysima Bakırcılar</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div className="container">
            <div className="testimonial-customer" id="one">
                <p>“Dijitalde markamızın görünürlüğünü artırarak daha fazla satış elde etmemizi sağlamanın yanı sıra her konuda bizi de sürekli olarak aydınlatmaya çalıştığınız için teşekkür ederiz.”</p>
                <div className="author">
                    <div>
                        <img src={customer}></img>
                    </div>
                    <div className="author-name">
                        Mert Hacıosmanoğlu<br />
                        <span>Yönetim Kurulu Üyesi</span>
                    </div>
                </div>
            </div>

            <div className="referances-video">
                <div className="col-md-6 referances-video_img">
                    {/* <img src={video} className="fake-youtube" alt="pink youtube video thumpnail" /> */}
                    <video className="fake-youtube" controls >
                        <source src={video} type="video/mp4" />
                    </video>
                    <img alt="alt text" className="design-squad" src={squad} />
                    <img alt="alt text" className="design-ref" src={refDesign} />
                </div>
                <div className="col-md-6 referances-video_content">
                    <h2>Liva Pastacılık</h2>
                    <b>Mert Hacıosmanoğlu, Yönetim Kurulu Üyesi</b>
                    <div className="referances-video_content-list">
                        <ul className="headers">
                            <li>%5.79: </li>
                            <li>+4500: </li>
                            <li>+90k: </li>
                            <li>+%61: </li>
                        </ul>
                        <ul>
                            <li>E-ticaret Ciro Dönüşüm Oranı</li>
                            <li>Aylık Sipariş Adeti</li>
                            <li>Aylık Edinilen Organik Trafik</li>
                            <li>Aylık Tekrarlayan Sipariş</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="site-preview">
                <div className="mockup">
                    <img src={liva} />
                </div>
            </div>

            <div className="customer-content">
                <h2>Doğru tasarım, yüksek ciro,<br /> dönüşüm oranı.</h2>
                <div className="row-img">
                    <img src={detail} />
                    <img src={detail1} />
                </div>
                <p>Dijital pazarlama operasyonlarının yanı sıra e-ticaret websitesinin arayüzü ve kullanılabilirliği de çok önemlidir. Olumsuz kullanıcı deneyimi ve tarayıcı uyumsuzluklarından kaynaklanan hatalar, maksimum dönüşüme ulaşmayı engeller.</p>
                <p>Sıfırdan oluşturulan Liva Pastacılık e-ticaret web sitesi için 2000’den fazla çalışan tarafından sürekli olarak güncellenip geliştirilen Shopify e-ticaret altyapısı tercih edildi.</p> <p> Firmalar tarafından yapılamayacak geliştirmeler ve entegrasyonlar bulunduran ve aynı zamanda 500.000 e-ticaret sitesi tarafından kullanılan Shopify altyapısı ile tasarladığımız yeni Liva Pastacılık arayüzü neticesinde dönüşüm oranında %60 artış sağlandı ve trafik ile ciroda da yükselme yaşandı.</p>
                <h2>2 yıl içerisinde %425 organik <br />trafik artışı</h2>
                <p>İçerik pazarlaması ve link inşası ile ilk 2 yıl içerisinde hedeflenen anahtar kelimelerde ilk 3 sıra ve ilk sayfa hedeflerini gerçekleştirdik. Bugün aylık 90.000’i aşan trafiği, Google’dan organik olarak sağlamaktayız.</p>
                <div className="row-img">
                    <img src={detail2} />
                    <img style={{ borderRadius: 10 }} src={detail3} />
                </div>
                <p style={{ marginBottom: 0 }}>Aşağıdaki tabloda görüldüğü gibi sektörde öne çıkan “doğum günü pastası”, “pasta siparişi”, “yaş pasta”, “baklava” gibi hedef kelimelerde ilk 3 sırada pozisyonlanmaktayız. Ayrıca mevcut durumda arama motorlarından aylık toplamda 30.776 kelime öbeğinden organik trafik edinmekteyiz.</p>
                <img className="full-shadow" src={detail4} />
                <h2>Düşük tıklama başı maliyet ile<br />maximum ciro dönüşüm oranı.</h2>
                <img style={{ width: '70%', margin: '0 auto' }} src={detail5} />
                <p>Etkili bir reklam yönetimi ile markanın düşük tıklama başı maliyetli reklamlardan yüksek verim almasını sağlayacak çalışmalara imza attık.</p>
                <h2>Sadık müşteriler. <br />Düzenli artan ciro.</h2>
                <img style={{ width: '50%', margin: '0 auto' }} src={detail6} />
                <p>Sağlam temellere dayalı bir büyüme için kullanıcı sadakati ve başarı sürekliliği çok önemlidir. Alışveriş sırasında müşteri ile ilk teması kurduktan sonra mailing, sosyal medya etkileşimi ve bildirim gibi metodlarla iletişimizi sürekli hale getiriyoruz. Bu hatırlatmalar ve iletişim stratejileri ile de yeniden sipariş veren müşteri oranını %61 oranlarına çıkarmış bulunmaktayız.</p>
            </div>
        </div>


        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
